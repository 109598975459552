<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Staff</h4>
            <div class="small text-muted">Vista detallada del staff seleccionado</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row class="mb-2">

        <b-col md="4">
          <b-card>
            <b-row>
              <b-col>
                <b-avatar :src="staff.image"
                          v-if="staff.image">
                </b-avatar>
                <b-avatar v-else                                             
                          variant="dark">
                </b-avatar>     
                &nbsp;<b>{{staff.name}}</b>     
              </b-col>
            </b-row>

            <b-row>
              <b-col>                    
                
                <div class="ml-5">
                  <div v-if="!staff.email">
                    <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                    <b-badge variant="warning" class="badget-custom">No tiene un email asociado</b-badge>                                                    
                  </div>
                  <div v-else>
                    <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                    <b-link :href="'mailto:' + staff.email">
                      {{ staff.email }}
                    </b-link> 
                  </div>
                </div>
                
                <div class="ml-5" v-if="staff.phone">
                  <b-icon icon="phone"></b-icon> &nbsp;&nbsp;
                  <b-link :href="'tel:' + staff.phone">
                    {{ staff.phone }}
                  </b-link>               
                </div>                    

              </b-col>
            </b-row>
          </b-card>
        </b-col>                   

        <b-col md="8">

          <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark"                               
                  v-if="showTab">

            <b-tab title="Notas" v-if="this.configTabs.observationsInternal.show" :active="tabs.observationsInternal" @click="setActiveTab('observationsInternal')">
                <tab-observations-internal :staff="staff" :staffID="staffID"></tab-observations-internal>
            </b-tab>

          </b-tabs>
          <b-alert v-else show variant="warning">
            No se encotró información de módulos relacionados.
          </b-alert>

        </b-col>        
      </b-row>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>     
        
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Param from '@/config/parameters'
  import Helper from '@/handler/helper'
  import Storage from '@/handler/storageLocal'
  import Widget from '@/components/inc/widget/widget'
  import TabObservationsInternal from './tabs/observationsInternal'

  export default {
    components: {
      Widget,
      TabObservationsInternal,    
    },
    data: () => {
      return {
        access: {
          module_id: Modules.PERSONAL,
          profile_id: Profiles.PERSONAL,
          view_reference: 'view',
          elements: {}
        },
        configTabs: {
          observationsInternal: {
            show: false,
          },
        },        
        configWidget: {
          module_id: Modules.PERSONAL,
          profile_id: Profiles.PERSONAL,
          elements: {}
        },
        primaryColor: '',
        staffID: 0,
        staff: {},
        tabs: {
          observationsInternal: true,
        }        
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },
    mounted() {
      this.staffID = this.$route.params.staffID  

      this.setup()
      this.load()

      this.configAccess()
    
      this.restoreStatusTabsStorage()
    }, 
    computed: {
      showTab () {
        var status = false
        if(this.configTabs.observationsInternal.show) {
          status = true
        }
        return status
      },   
    },    
    methods: {
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.mostrar(this.staffID)

        result.then((response) => {
          var data = response.data
          this.staff = data          
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      },

      configAccess() {    
        if(Param.P25) {
          this.configTabs.observationsInternal.show = true
        }          
      },       

      //TABS
      disableAllTabs() {
        this.tabs.observationsInternal = false
      },
      setActiveTab(value) {
        this.disableAllTabs()

        switch (value) {
          case 'observationsInternal':
            this.tabs.observationsInternal = true
            break;

          default:
            this.tabs.observationsInternal = true
            break;
        }

        this.saveStatusTabsStorage()
      },

      // FILTROS SAVE
      saveStatusTabsStorage() {
        Storage.setValue('tabs_view_staff', JSON.stringify(this.tabs))
      },
      restoreStatusTabsStorage() {
        if(Storage.getValue('tabs_view_staff')) {
          this.tabs = JSON.parse(Storage.getValue('tabs_view_staff'))
        }         
      }, 

    }    
   
  }
</script>

<style>

</style>
